import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '../../components/layout';
import Seo from '../../components/Seo';

const InteriorPage = ({ location }) => {

  const image = 'https://rfallp.com/images/assets/images/GettyImages-500817285.jpg';

  return (
    <Layout location={location}>
      <div className="page-wrapper-inner">
        <Seo
          title="Services | Family Administrative Services"
          image={image}
          slug={location.pathname}
          description="We provide reliable, comprehensive administrative services tailored to your family’s unique needs."
        />
        {/* Main */}
        <section className="wrapper style1">
          <div className="container">
            <div className="row gtr-200">
              <div className="col-4 col-12-narrower">
                <div id="sidebar">

                  {/* Sidebar */}

                  <section>
                    <h3>Services</h3>
                    <ul className="links">
                      <li><Link to="/services/">Overview</Link></li>
                      <li><Link to="/services/bill-pay-and-accounting/">Bill Pay and Accounting</Link></li>
                      <li><Link to="/services/income-tax-planning-and-compliance/">Income Tax Planning and Compliance</Link></li>
                      <li><Link to="/services/family-administrative-services/">Family Administrative Services</Link></li>
                      <li><Link to="/services/estate-and-gift-planning/">Estate and Gift Planning</Link></li>
                      <li><Link to="/services/philanthropic-consulting/">Philanthropic Consulting</Link></li>
                    </ul>
                  </section>

                </div>
              </div>
              <div className="col-8  col-12-narrower imp-narrower">
                <div id="content">

                  {/* Content */}
                  <article>
                    <header>
                      <h2>Family Administrative Services</h2>
                      <p>
                        We provide reliable, comprehensive administrative services tailored to your family’s unique needs.
                      </p>
                    </header>

                    <span className="image featured">
                      <StaticImage
                        src="../../assets/images/GettyImages-500817285.jpg"
                        alt=""
                        width={725}
                      />
                    </span>

                    <p>
                    We understand the intense complexity of our ultra-affluent families. Rosewood Family Advisors is experienced in handling all the details that surround such a client.
                    </p>
                    <p>
                    We provide the following services:
                    </p>
                    <ul>
                      <li>Entity Management.</li>
                      <li>Staff Management.</li>
                      <li>Coordination of major asset purchases.</li>
                      <li>Air and water administrative services.</li>
                    </ul>

                  </article>

                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default InteriorPage;
